.questsPage{
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}

.basedtask{
    border-radius: 1.5vh;
    margin-top: 1.7vh;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.basedtask::after {
    content: '';
    position: absolute;
    bottom: -1.2vh; 
    left: -1.2vh; 
    width: 0;
    height: 0;
    border-left: 3vh solid transparent; 
    border-right: 0 solid transparent;    
    border-top: 2.5vh solid #ffffff; 
    transform: rotate(-50deg); 
}


#complatedtask{
    background: rgba(164, 210, 255, 1);
    position: relative;
    margin-top: 1.7vh;
    border-radius: 1.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

#complatedtask::after {
    content: '';
    position: absolute;
    bottom: -1.2vh; 
    right: -1.2vh;  
    width: 0;
    height: 0;
    border-left: 0 solid transparent; 
    border-right: 3vh solid transparent;
    border-top: 2.5vh solid rgba(164, 210, 255, 1); 
    transform: rotate(50deg); 
}

.txtNf p{
    align-self: start;
    text-align: start;
    margin-bottom: 0.7vh;
    margin-top: 1.2vh;
}

.txtNf{
    width: calc(86% + 1.6vh);
    font-weight: 700;
}
 

.questItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(239, 238, 244, 1);
    border-radius: 0.5vh;
    margin-bottom: 1.1vh;
    padding: 1vh;
    padding-top: 1.2vh;
    padding-bottom: 1.2vh;
    width: 86%;
}

.questItemTon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(239, 238, 244, 1);
    border-radius: 0.5vh;
    padding: 1vh;
    padding-top: 1.2vh;
    padding-bottom: 1.2vh;
    margin-bottom: 1vh;
    margin-top: 1vh;
    width: 86%;
}

.questIcon {
    display: flex;
}

.questItemLeft {
    display: flex;
    gap: 1vh;
    align-items: center;
}

.questItemLeft img {
    height: 2.5vh;
}
.questTitle {
    font-weight: 700;
    font-size: 1.45vh;
    color: rgba(0, 0, 0, 1);
    text-align: start;
    padding: 0;
    margin: 0;
}

.questSubtitle {
    color: rgba(79, 79, 79, 1);
    font-size: 1.2vh;
    font-weight: 400;
    text-align: start;
    padding: 0;
    margin: 0;
}

.questBtn {
    border: none;
    background-color: rgba(88, 88, 88, 1);
    border-radius: 2vh;
    padding: 0.6vh 1.3vh;
    font-size: 1.4vh;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
}

.questItemRight{
    display: flex;
    align-items: start;
    justify-content: start;
}

.feikton{
    color: white;
  }

button[data-tc-connect-button="true"]{
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(88, 88, 88, 1);
    height: 2.8vh;
    border-radius: 2vh;
}

  button[data-tc-dropdown-button="true"] {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(88, 88, 88, 1);
    height: 2.8vh;
    border-radius: 2vh;
  }


@media (orientation: landscape) {

    .questsPage{
        overflow: scroll;
        justify-content: start;
    }

    .questItemLeft img {
        height: 8vh;
        margin-left: 1vh;
    }

    .questTitle {
        font-size: 2.2vh;
    }
    
    .questSubtitle {
        font-size: 1.8vh;
    }

    .basedtask{
       margin-top: 4vh;
    }

    #complatedtask{
        margin-top: 4vh;
    }

    .questItem {
        border-radius: 1vh;
        width: 95%;
        margin-bottom: 2vh;
        padding: 1vh;
    }

    .questItemTon{
        border-radius: 1vh;
        width: 95%;
        margin-bottom: 2vh;
        margin-top: 2vh;
    }

    .txtNf{
        width: calc(95% + 2vh);
    }

    .txtNf p{
        margin-bottom: 1vh;
        margin-top: 2vh;
        font-size: 4vh;
    }

    .questItemLeft {
        gap: 2vh;
    }

    .questBtn {
        padding: 1.5vh 2.5vh;
        font-size: 2.5vh;
    }

    button[data-tc-connect-button="true"]{
        height: 5.5vh;
        border-radius: 2vh;
      }
    
      button[data-tc-dropdown-button="true"] {
        height: 5.5vh;
       
      }
    
    
}