*{
  user-select: none;
}

::-webkit-scrollbar {
  display: none;
}

body {
	margin: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden; 
  isolation: isolate;
  background: url("../IMG/backg.png") no-repeat center center fixed;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.appWrapper {
  margin: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  text-align: center;
  flex-direction: column;
  font-size: 2.2vh ;
}

.headerWrapper {
  height: 8%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userName {
  font-size: 3vh;
  font-weight: 500;
  margin-left: 3vh;
}
.userAvatarWrapper {
  height: 7vh;
  display: flex;
  align-items: center;
  margin-right: 3vh;
}
.userAvatarImg {
  height: 90%;
  object-fit: cover;
  border-radius: 50%;
}

.centeredBlock{
  height: 81%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  height: 11%;
  background-color: white;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;z-index: 10000;
}

.footerItems {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  padding: 0;
  padding-bottom: 0.7vh;
  width: 100%;
}

.footerItemImgWrapper {
  height: 3vh;
}

.footerItem .footerItemImg {
  transition: transform 0.3s ease; 
}

.footerItem.active .footerItemImg {
  transform: scale(1.5);
}

.footerItem:not(.active) .footerItemImg {
  transform: scale(1);
}


.footerItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

.footerItemImg {
  height: 98%;
  object-fit: cover;
}

.footerItemLabel {
  font-size: 2vh;
  font-weight: 400;
  padding: 0;
  padding-top: 0.5vh;
  margin: 0;
}

@media (orientation: landscape) {

  .footer{
    height: 20%;
  }

  .headerWrapper {
    height: 15%;
  }

  .centeredBlock{
    height: 65%;
    overflow: scroll;
  }

  .footerItemImgWrapper {
    height: 8vh;
  }

  .footerItems {
    justify-content: space-around;
  }

  .footerItemLabel {
    font-size: 4vh;
  }

  .userName {
    font-size: 5vh;
    margin-left: 10vh;
  }
  .userAvatarWrapper {
    height: 10vh;
    margin-right: 10vh;
  }

  .footerItem.active .footerItemImg {
    transform: scale(1.3); 
  }
}

.app-content.hidden {
  display: none;
}