.loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
  }

  .loading-screen-friend {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 78%;
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
  }

  .loading-wrapper-app {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    margin-bottom: 1vh;
    background-color: white ;
}

.loading-wrapper-leaderboard {
  position: fixed;
  width: 100%;
  height: 90%;
  left: 0;
  bottom: 0;
}

.loading-wrapper-friends {
  position: fixed;
  width: 100%;
  height: 80%;
  left: 0;
  bottom: 0;
}

  #loading-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 18vh;
    height: 18vh;
    margin: -8.5vh 0 0 -9vh;
  }
  
  #loading-content:after {
    content: "";
    position: absolute;
    left: 2vh;
    right: 2vh;
    top: 2vh;
    bottom: 2vh;
  }
  
  #loading-content:before {
    content: "";
    position: absolute;
    left: 0.65vh;
    right: 0.65vh;
    top: 0.65vh;
    bottom: 0.65vh;
  }
  
  #loading-content {
    border: 0.4vh solid transparent;
    border-top-color: #1AADFA;
    border-bottom-color: #1AADFA;
    border-radius: 50%;
    -webkit-animation: loader 2s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 2s linear infinite;
  }
  
  #loading-content:before {
    border: 0.4vh solid transparent;
    border-top-color: black;
    border-bottom-color: black;
    border-radius: 50%;
    -webkit-animation: loader 3s linear infinite;
      -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 3s linear infinite;
  }
  
  #loading-content:after {
    border: 0.4vh solid transparent;
    border-top-color: #C2C2C2;
    border-bottom-color: #C2C2C2;
    border-radius: 50%;
    -webkit-animation: loader 1.5s linear infinite;
    animation: loader 1.5s linear infinite;
      -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
  }
  
  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  #content-wrapper {
    color: #FFF;
    position: fixed;
    left: 0;
    top: 20px;
    width: 100%;
    height: 100%;
  }
  
  #content
  {
    width: 800px;
    height: 1000px;
    margin: 0 auto;
    text-align: center;
    background-color: #888;
  }
  
.loading-screen img {
    z-index: 9999;
    height: 8vh;
    animation: imgAnimation 2s  infinite ease-in-out;
}

.loading-screen-friend img {
  z-index: 9999;
  height: 8vh;
  animation: imgAnimation 2s  infinite ease-in-out;
}

.hiddenMain {
  animation: fadeOut 0.5s ease-in-out forwards;
}

@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}


@keyframes imgAnimation {
    0%  {transform: scale(1);}
    50% {transform: scale(1.4);}}


@media (orientation: landscape) {

  .loading-wrapper-leaderboard {
    position: fixed;
    width: 100%;
    height: 83%;
    left: 0;
    bottom: 0;
  }

  .loading-wrapper-friends {
    position: fixed;
    width: 100%;
    height: 75%;
    left: 0;
    bottom: 0;
  }
}
