.leaderboardContainer {
    width: 90%;
    height: calc(100% - 5vh);
    display: flex;
    flex-direction: column;
    margin: 5vh auto 0 auto;
}

.blueContainer {
    background-color: rgba(164, 210, 255, 1);
    padding: 1.2vh 1.2vh 1.2vh 1.2vh;
    border-radius: 1.5vh;
    display: flex;
    height: 8vh;
    gap: 1.5vh;
    position: relative;
}

.blueContainer::after {
    content: '';
    position: absolute;
    bottom: -1.2vh; 
    right: -1.2vh;  
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 3vh solid transparent;
    border-top: 2.5vh solid rgba(164, 210, 255, 1);
    transform: rotate(50deg); 
}

.blueContainerItem {
    padding: 1vh;
    background-color: rgba(239, 238, 244, 1);
    border-radius: 1.5vh;
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
  
.blueContainerItemTitle {
    color: rgba(0, 86, 131, 1);
    font-size: 2.5vh;
    font-weight: 700;
    padding: 0;
    margin: 0;   
}

.blueContainerItemSubtitle {
    font-size: 2vh;
    font-weight: 400;
    color: rgba(0, 61, 93, 1);
    padding: 0;
    margin: 0;
}

.leaderboardScroll {
    overflow-y: scroll;
}

.whiteContainerLeaderboard {
    padding: 2vh 1vh 2vh 1vh;
    border-radius: 3vh;
    background-color: rgba(255, 255, 255, 1);
    margin-top: 2vh;
    height: 75%;
    position: relative;
}

.whiteContainerLeaderboard::after {
    content: '';
    position: absolute;
    bottom: -1.2vh; 
    left: -1.2vh; 
    width: 0;
    height: 0;
    border-left: 3vh solid transparent; 
    border-right: 0 solid transparent;    
    border-top: 2.5vh solid #ffffff; 
    transform: rotate(-50deg); 
}

.whiteContainerContent {
    list-style-type: none;
    height: 100%;
    margin: 0;
    padding: 0;
}

.leaderboardItem {
    display: flex;
    justify-content: space-between;
    padding: 1vh 2vh 1vh 2vh;
    align-items: center;
}

.leaderboardItemLeft {
    display: flex;
    gap: 1vh;
    flex-direction: row;
    align-items: center;
}

.leaderboardAvatarImg{
    height: 5vh;
    border-radius: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leaderboardTitle {
    font-size: 2vh;
    text-align: start;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.leaderboardSubtitle {
    font-weight: 400;
    font-size: 1.2vh;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}
.leaderboardSubtitle img{
    height: 1.5vh;
}

.leaderboardItemRight {
    margin: 0;
    padding: 0;
    font-size: 2vh;
    display: flex;
    align-items: center;
}

#LodBlue{
    color: rgba(239, 238, 244, 1);
}

.suportLodd{
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loaderBlue {
    position: absolute;
    width: 3vh;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 0.5vh solid rgba(164, 210, 255, 1);
    border-right-color: rgba(0, 86, 131, 1);
    animation: l2 1s infinite linear;
  }
  @keyframes l2 {to{transform: rotate(1turn)}}

.hiddenLider {
    animation: fadeOut 0.35s ease-in-out forwards;
}

.fadeIn {
    animation: fadeIn 0.35s ease-in-out forwards;
}
  
@keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}
  

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}



@media (orientation: landscape) {

    .leaderboardTitle {font-size: 2.5vh;}
    
    .leaderboardSubtitle {
        font-weight: 400;
        font-size: 2vh;
    }

    .outer-container {
        height: 50%;
    }

    .blueContainer {height: 30%;}

    .blueContainerL {height: 30%;}

    .whiteContainerLeaderboard {height: 50%;}

    .blueContainerItemTitle {font-size: 5vh;}
    
    .blueContainerItemSubtitle {font-size: 4vh;}
}


